/*
  Contact.scss -> View.scss
  예약증, 문의증 등 view 화면 공통 사용
*/

li {
  list-style-position: inside;

  span {
    margin-left: -8px;
  }
}

.kakao_map {
  height: 180px;
  border-radius: 24px;
}

#view {
  .header {
    width: 100%;
    height: 60px;
    text-align: center;
  }

  .content-box {
    padding: 24px;
    background: #ffffff;
    border-radius: 24px;
  }

  .footer {
    height: 250px;
    padding-bottom: 58px;

    .login-logo-partners {
      width: 141.14px;
      height: 28px;
    }
  }

  .title {
    color: #191c1a;
  }

  .warning_wrap,
  .info_wrap {
    padding: 16px 24px;

    > h3 {
      color: #8f918e;
      display: flex;
      justify-items: center;
      line-height: 24px;
    }

    > ul {
      margin-top: 8px;
      color: #8f918e;
    }
  }

  .warning_wrap ul {
    list-style-type: circle;

    > li {
      position: relative;
      margin-top: 5px;
      padding-left: 24px;
    }

    > li::before {
      display: inline-block;
      position: absolute;
      left: 10px;
      top: 12px;
      content: "";
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: #8f918e;
    }
  }

  .customer_request {
    padding: 16px 24px;
    background: #eef3e4;
    border-radius: 16px;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: justify;
    color: #1d5200;
  }

  .toast_message {
    padding: 15px 24px;
    background: #f0f1ed;
    border-radius: 8px;
    font-size: 14px;
    line-height: 22px;
    color: #1d5200;
    margin: 0 8px;

    > span {
      font-size: 14px;
      line-height: 22px;
      color: #93fd53;
      margin-right: 8px;
      background: #1d5200;
      border-radius: 8px;
      padding: 1px 7px;
    }
  }
}

.button-delete-outline {
  border-radius: 24px !important;
  background-color: #fff2ee !important;
  color: #c14600 !important;
  border: 1px solid #b62c00 !important;
  height: 64px;
}
