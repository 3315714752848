@import "/src/layouts/layout_generated.scss";

@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 900;
  font-style: normal;
}

#root {
  @extend .f-c-c;
}
button,
textarea,
input {
  font-family: "Pretendard-Regular";
  font-weight: 500;
  font-style: normal;
  padding: 0px;
}

input[type="file"] {
  display: none;
}

iframe {
  border: none;
  height: 100%;
}

textarea {
  resize: none;
}

div {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  box-sizing: border-box;
  white-space: nowrap;
}

body {
  @extend .bc-pp;
  background-color: #ffffff;
  margin: 0;
  color: #222222;
  font-family: "Pretendard-Regular";
  font-weight: 500;
  font-style: normal;
}

img {
  object-fit: cover;
  object-position: center;
}

@media (max-width: 576px) {
  .desktop-only {
    display: none !important;
  }
}

.app {
  @extend .f-r-s-c;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

input {
  border: none;
  background-color: transparent;
}

input:focus,
select:focus,
textarea:focus,
button:focus,
[contenteditable]:focus {
  outline: none;
}

[contentEditable="true"] {
  display: inline-block;
  width: 100%;
}

[contentEditable="true"]:empty:not(:focus):before {
  @extend .c-g5;
  content: attr(data-placeholder);
  pointer-events: none;
}

.button {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.info-box {
  background-color: #f0f1ed;
  padding: 16px;
  border-radius: 8px;
}

.divider {
  @extend .fs-st;
  background-color: #f2f2f2;
  height: 1px;
  flex-shrink: 0;
}

.popup-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 72px;
  background-color: #f6f6f680;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(8px);
  z-index: 10000;
}

.drawer-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000080;
  z-index: 10000;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
}

.popup-title {
  @extend .t-22;
  margin-bottom: 24px;
}

.rubix-popup-content {
  @extend .f-c;
  border-radius: 12px;
}

@media (max-width: 1024px) {
  .rubix-popup-content {
    width: 100%;
    height: 100%;
  }
}

.drawer-content {
  background-color: white;
  min-width: 600px;
  min-height: 100px;
  position: absolute;
  top: 0px;
  bottom: 0px;
}

.popup-top {
  padding-top: 16vh;
  align-items: flex-start;
}

.popup-notification {
  position: absolute;
  left: 328px;
  top: 192px;
}

.button-confirm {
  @extend .t-14;
  border-radius: 8px;
  background-color: #31cf59;
  color: white;
  height: 38px;
  padding-right: 24px;
  padding-left: 24px;
  display: flex;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.button-cancel {
  @extend .t-14;
  border-radius: 8px;
  background-color: #f1f6f4;
  color: #4e5a67;
  height: 38px;
  padding-right: 24px;
  padding-left: 24px;
  display: flex;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.button-delete {
  @extend .t-14;
  border-radius: 8px;
  background-color: #fff2ee;
  color: #c14600;
  height: 38px;
  padding-right: 24px;
  padding-left: 24px;
  display: flex;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.button-outlined {
  @extend .t-14;
  @extend .c-pp;
  @extend .bc-pt;
  height: 40px;
  border-radius: 8px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.button-add-tag {
  @extend .t-14;
  @extend .m-16-t;
  border-radius: 8px;
  color: white;
  background: #9747ff;
  border: 1px solid #313338;
  height: 40px;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

$anim-durations: (
  "0": 0.2s,
  "1": 0.4s,
  "2": 0.6s,
  "3": 0.8s,
  "4": 1s,
);
@each $key, $value in $anim-durations {
  .anim-#{$key} {
    -webkit-transition: all $value ease;
    -moz-transition: all $value ease;
    transition: all $value ease;
  }
}

$dirs: (
  "": (
    "-top",
    "-bottom",
    "-right",
    "-left",
  ),
  "-t": (
    "-top",
  ),
  "-b": (
    "-bottom",
  ),
  "-r": (
    "-right",
  ),
  "-l": (
    "-left",
  ),
  "-tb": (
    "-top",
    "-bottom",
  ),
  "-tr": (
    "-top",
    "-right",
  ),
  "-tl": (
    "-top",
    "-left",
  ),
  "-br": (
    "-bottom",
    "-right",
  ),
  "-bl": (
    "-bottom",
    "-left",
  ),
  "-rl": (
    "-right",
    "-left",
  ),
  "-tbr": (
    "-top",
    "-bottom",
    "-right",
  ),
  "-tbl": (
    "-top",
    "-bottom",
    "-left",
  ),
  "-trl": (
    "-top",
    "-right",
    "-left",
  ),
  "-brl": (
    "-bottom",
    "-right",
    "-left",
  ),
  "-tbrl": (
    "-top",
    "-bottom",
    "-right",
    "-left",
  ),
);
$sizes: (
  "1": 1px,
  "2": 2px,
  "3": 3px,
  "4": 4px,
  "6": 6px,
  "8": 8px,
  "12": 12px,
  "16": 16px,
  "20": 20px,
  "24": 24px,
  "32": 32px,
  "36": 36px,
  "40": 40px,
  "48": 48px,
  "60": 60px,
);

$colors: (
  "w": #f6f6f6,
  "e": #f44336,
  "gr": #27bd63,
  "bl": #1a73e8,
  "p": #f6f6f6,
  "pt": #222222,
  "pd": #313338,
  "pl": #e4e4e4,
  "p-inactive": #8de5b5,
  "p-active": #31cf59,
  "h": #9747ff,
  "g0": #131313,
  "g1": #596371,
  "g2": #515151,
  "g3": #757875,
  "g4": #84958b,
  "g5": #777777,
  "g6": #c5c7c3,
  "g7": #e1e3df,
  "g9": #e2e2e2,
);

.line {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.line-2 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.line-3 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

$flex-align-items: (
  "c": "center",
  "e": "flex-end",
  "s": "flex-start",
  "st": "stretch",
);

$flex-justify-content: (
  "c": "center",
  "e": "flex-end",
  "st": "stretch",
);

.p-ab {
  position: absolute;
}

.p-rel {
  position: relative;
}

.f {
  display: flex;
  flex-direction: column;
}

.f-r {
  display: flex;
  flex-direction: row;
}

@each $key1, $value1 in $flex-align-items {
  .f-r-#{$key1} {
    display: flex;
    flex-direction: row;
    align-items: #{$value1};
  }
  .f-#{$key1} {
    display: flex;
    flex-direction: column;
    align-items: #{$value1};
  }
  .fs-#{$key1} {
    align-self: #{$value1};
  }
  @each $key2, $value2 in $flex-justify-content {
    .f-r-#{$key1}-#{$key2} {
      display: flex;
      flex-direction: row;
      align-items: #{$value1};
      justify-content: #{$value2};
    }
    .f-#{$key1}-#{$key2} {
      display: flex;
      flex-direction: column;
      align-items: #{$value1};
      justify-content: #{$value2};
    }
  }
}

@each $key, $value in $colors {
  .bdc-#{$key} {
    border-color: #{$value};
  }
  .bd-#{$key} {
    border: 1px solid #{$value};
  }
}
@each $key, $value in $sizes {
  .img-#{$key} {
    width: #{$value};
    height: #{$value};
    flex-shrink: 0;
  }
  .bdw-#{$key} {
    border-width: #{$value};
    border-style: solid;
  }
}

.t-gradient {
  background: linear-gradient(
    180deg,
    #02feff 0%,
    #ffffff 52.08%,
    rgba(255, 255, 255, 0) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.sdw {
  box-shadow: 0px 18px 32px rgba(0, 0, 0, 0.3);
}

.mapboxgl-control-container {
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes progress {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.click-popup {
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 3000;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.swiper-pagination-bullet-active {
  background-color: #f6f6f6 !important;
}

.swiper-pagination-bullet {
  background-color: #f6f6f6 !important;
  opacity: 0.4;
}

[role="tooltip"].popup-content {
  width: auto !important;
  height: 28px;
  border: none;
  background: #e4e4e4;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px;
}

[role="tooltip"].playlist-content {
  width: auto !important;
  box-shadow: none !important;
  background: none !important;
  padding: 0px 0px !important;
}

reach-portal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.no-handle [data-rsbs-header] {
  @extend .bc-cc;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.no-handle [data-rsbs-header]::before {
  content: none;
}
